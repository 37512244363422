import React, { useMemo } from 'react';

import { LinkedInIcon, TwitterIcon } from '../icons/SvgIcons';
import RoundLink from './RoundLink';

type SocialMediaButtonProps = ({ type: 'follow' } | { type: 'share'; url: string }) & { fill?: string };

export const TwitterButton: React.FC<SocialMediaButtonProps> = React.memo((props) => {
  const href = useMemo(
    () =>
      props.type === 'share'
        ? `https://twitter.com/intent/tweet?text=${`Check this article \n ${props.url}`}`
        : 'https://twitter.com/gaspardesk1',
    [props],
  );
  return (
    <RoundLink borderColor={props.fill} target="_blank" href={href} aria-label="Gaspar AI on Twitter">
      <TwitterIcon fill={props.fill} />
    </RoundLink>
  );
});
TwitterButton.displayName = 'TwitterButton';

export const LinkedInButton: React.FC<SocialMediaButtonProps> = React.memo((props) => {
  const href = useMemo(
    () =>
      props.type === 'share'
        ? `https://www.linkedin.com/sharing/share-offsite/?url=${props.url}`
        : 'https://www.linkedin.com/company/gasparai',
    [props],
  );
  return (
    <RoundLink borderColor={props.fill} target="_blank" href={href} aria-label="Gaspar AI on LinkedIn">
      <LinkedInIcon fill={props.fill} />
    </RoundLink>
  );
});
LinkedInButton.displayName = 'LinkedInButton';
