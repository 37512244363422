import { getRandomSubarray, isNonEmptyArray } from '@gaspardesk/utils';
import qs from 'qs';

import { StrapiApiUrl } from '../../../constants';
import {
  ApiClientPostResponse,
  StrapiApiArticle,
  StrapiApiCollectionResponse,
  StrapiApiFormPostAttributes,
  StrapiApiFormResponse,
  StrapiApiPlugin,
  StrapiApiProtocol,
  StrapiApiProtocolMethod,
  StrapiApiScrollingLogo,
  StrapiApiTag,
} from './types';

export class StrapiApiClient {
  // Retrieves a single article by its url friendly name
  public static async getArticleByUrlFriendlyName(urlFriendlyName: string) {
    const reponse = await fetch(`${StrapiApiUrl}/articles?filters[page_url][$eq]=${urlFriendlyName}`);
    const payload = (await reponse.json()) as StrapiApiCollectionResponse<StrapiApiArticle>;
    return isNonEmptyArray(payload.data) ? payload.data[0] : null;
  }
  // Retrieves all published tags
  public static async getAllTags() {
    return getFromApi<StrapiApiTag>('tags');
  }
  // Retrieves all published articles by tag
  public static async getAllArticles() {
    return getFromApi<StrapiApiArticle>('articles', { populate: 'tags', sort: 'publishedAt:desc' });
  }
  // Retrieves a random set of most recent articles
  public static async getRandomMostRecentArticles(selectLimit: number = 6, bucketLimit: number = 20) {
    const articles = await getFromApi<StrapiApiArticle>('articles', { populate: 'tags', sort: 'publishedAt:desc' });
    return getRandomSubarray(articles.slice(0, bucketLimit), selectLimit);
  }
  // Retrieves all plugins
  public static async getAllPlugins() {
    return getFromApi<StrapiApiPlugin>('plugins', { populate: 'protocols' });
  }
  // Retrieves all protocols
  public static async getAllProtocols() {
    return getFromApi<StrapiApiProtocol>('protocols', { populate: 'protocol_methods' });
  }
  // Retrieves all plugins articles by tag
  public static async getAllScrollingLogos() {
    return getFromApi<StrapiApiScrollingLogo>('scrolling-logos');
  }
  // Retrieves all URL paths for all published articles
  public static async getAllArticlePaths() {
    const allArticles = await getFromApi<StrapiApiArticle>('articles', { fields: ['page_url'] });
    return allArticles.map((article) => article.attributes.page_url);
  }
  // Posts a new contact request
  public static async postContactUsRequest(data: StrapiApiFormPostAttributes) {
    return postToApi('contactus-submission', data);
  }
  // Posts a new demo request
  public static async postDemoRequest(data: StrapiApiFormPostAttributes) {
    return postToApi('demo-submission', data);
  }
}

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

async function postToApi(
  endpoint: 'contactus-submission' | 'demo-submission',
  data: Record<string, unknown>,
): Promise<ApiClientPostResponse> {
  // const query = qs.stringify({ fields, populate, sort, pagination }, { encodeValuesOnly: true });
  const response = await fetch(`${StrapiApiUrl}/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    },
    referrer: 'https://www.gaspar.ai/contact-us',
    referrerPolicy: 'strict-origin-when-cross-origin',
    // mode: 'cors',
    // credentials: 'include',
  });
  const payload = (await response.json()) as StrapiApiFormResponse;
  const statusCode = response.status;
  const success = payload.ok == true;
  // Extract all the errors from the response (if any)
  const errors = {};
  Object.keys(payload.data).forEach((key) => {
    errors[key] = payload[key];
  });
  return { statusCode, success, errors };
}

// Retrieves all data for a given entity from the Strapi API.
// For each request if the response indicates that there are more pages to fetch, then the next page is fetched.
async function getFromApi<
  T = StrapiApiArticle | StrapiApiTag | StrapiApiPlugin | StrapiApiProtocol | StrapiApiProtocolMethod | StrapiApiScrollingLogo,
>(
  collectoin: 'tags' | 'articles' | 'plugins' | 'protocols' | 'protocol_methods' | 'scrolling-logos',
  params?: { fields?: string[]; populate?: string; sort?: string },
): Promise<T[]> {
  const { fields, populate, sort } = params || {};
  // Setup a function to fetch a single page of data
  const fetchPage = async (page: number) => {
    const pagination = [['page', page]];
    const query = qs.stringify({ fields, populate, sort, pagination }, { encodeValuesOnly: true });
    const response = await fetch(`${StrapiApiUrl}/${collectoin}?${query}`);
    const payload = (await response.json()) as StrapiApiCollectionResponse<T>;
    return payload;
  };
  // Create an empty array to hold all the data
  const data: T[] = [];
  // Configure the initial flags
  let shouldFetchNextPage = true;
  let currentPage = 1;
  // Setup a loop to fetch all the pages
  while (shouldFetchNextPage) {
    // Fetch the next page of data
    const payloadForPage = await fetchPage(currentPage);
    // Add the data to the array
    data.push(...payloadForPage.data);
    // Determine if there are more pages to fetch
    shouldFetchNextPage = payloadForPage.meta.pagination.pageCount > currentPage;
    // Increment the page counter
    currentPage = currentPage + 1;
  }
  return data;
}
