import classnames from 'classnames';
import React from 'react';

import { DisappearingTooltip, Tooltip } from '../interactive/Tooltip';

type FormInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  asteriskClassName?: string;
  label?: string;
  tooltip?: string;
  errorMessage?: string;
};
const FormInput: React.FC<FormInputProps> = React.memo((props) => {
  const { label, id, asteriskClassName, errorMessage, className, tooltip, ...inputProps } = props;
  const inputComponent = (
    <input
      id={id}
      className={classnames('p-2 bg-pureWhite/10 text-pureWhite w-full rounded-full placeholder-white/60', className, {
        'border-error border-1': !!errorMessage,
      })}
      {...inputProps}
    />
  );
  return (
    <div>
      <label htmlFor={id} className="mb-2 block font-light">
        <span className="mr-2">
          {label}
          {inputProps.required === true && <span className={asteriskClassName}>*</span>}
        </span>
        {tooltip && (
          <span className="relative top-[6px]">
            <Tooltip content={tooltip} />
          </span>
        )}
      </label>
      {errorMessage && <DisappearingTooltip message={errorMessage}>{inputComponent}</DisappearingTooltip>}
      {!errorMessage && inputComponent}
    </div>
  );
});
FormInput.displayName = 'FormInput';
export default FormInput;
