import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

// Site Constants
export const SiteURL = publicRuntimeConfig.SITE_URL as string;

// API Constants
export const StrapiApiUrl = publicRuntimeConfig.STRAPI_API_URL as string;
export const GaspardeskApiUrl = publicRuntimeConfig.GASPARDESK_API_URL as string;

export const DigitalOceanCDN = publicRuntimeConfig.CDN_URL as string;

// Environment Constants
export const isProduction = publicRuntimeConfig.NODE_ENV === 'production';
export const GoogleAnalyticsTrackingId = 'G-R3N47JXN2F';
