import Link from 'next/link';
import React from 'react';

import pageRoutes from '../../../router/pageRoutes';

const FooterNavigation: React.FC = () => {
  return (
    <nav aria-label="Site Footer Navigation">
      <ul className="flex flex-col items-start gap-2 mt-4 font-bold text-p text-pureWhite/80">
        <li>
          <Link href={pageRoutes.TermsAndConditions}> Terms And Conditions</Link>
        </li>
        <li>
          <Link href={pageRoutes.CookiesPolicy}> Cookies Policy</Link>
        </li>
        <li>
          <Link href={pageRoutes.PrivacyPolicy}> Privacy Policy</Link>
        </li>
        <li>
          <Link href={pageRoutes.FreeTrial}> Free Trial</Link>
        </li>
        <li>
          <Link href={pageRoutes.Pricing}> Pricing</Link>
        </li>
      </ul>
    </nav>
  );
};
FooterNavigation.displayName = 'FooterNavigation';
export default FooterNavigation;
