"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorScale = void 0;
const number_utils_1 = require("./number_utils");
class ColorScale {
    constructor(colorGroups, fallbackColor = 'inherit') {
        this.colorGroups = colorGroups;
        this.fallbackColor = fallbackColor;
    }
    getColorForValue(value) {
        const domainIndex = this.getDomainGroupForValue(value);
        if ((0, number_utils_1.isDefinedNumber)(domainIndex)) {
            return this.colorGroups[domainIndex].color;
        }
        return this.fallbackColor;
    }
    getDomainGroupForValue(value) {
        let groupDomainIndex = null;
        this.colorGroups.some((group, groupIndex) => {
            const isInStartRange = group.rangeStart === -Infinity ? true : value >= group.rangeStart;
            const isInStopRange = group.rangeStop === Infinity ? true : value <= group.rangeStop;
            if (isInStartRange && isInStopRange) {
                groupDomainIndex = groupIndex;
                return true;
            }
        });
        return groupDomainIndex;
    }
}
exports.ColorScale = ColorScale;
