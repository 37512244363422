import classnames from 'classnames';
import React, { useRef } from 'react';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { CloseIcon, MenuIcon } from '../../icons/SvgIcons';
import OverlayMenu from './common/OverlayMenu';
import { SecondaryMenuColumns } from './common/columns';
import { LinkToBlog, LinkToDemoPage, LinkToHomePage } from './common/links';

const MobileMenu: React.FC = (props) => {
  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  useClickOutside({
    ref: clickOutsideRef,
    onClickOutside: () => setIsExpanded(false),
  });
  return (
    <div className={classnames('flex justify-between items-center z-40')}>
      <LinkToHomePage />
      <div className="inline-flex">
        <button className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <CloseIcon /> : <MenuIcon />}
        </button>
        <div ref={clickOutsideRef} className="">
          <OverlayMenu isVisible={isExpanded}>
            <div className="h-[100vh] pb-[100px] overflow-auto">
              <SecondaryMenuColumns
                onLinkClick={() => setIsExpanded(false)}
                submenusClassName="text-center"
                className={classnames('relative flex flex-col p-2 justify-between gap-4 mx-auto text-pureWhite text-lg')}
              />

              <div className="mb-4 mt-4 px-[40px] flex flex-col gap-5">
                <div className="text-2xl font-bold">
                  <LinkToBlog />
                </div>
                <div>
                  <LinkToDemoPage className="text-xl" />
                </div>
              </div>
            </div>
          </OverlayMenu>
        </div>
      </div>
    </div>
  );
};
MobileMenu.displayName = 'MobileMenu';
export default MobileMenu;
