import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import pageRoutes from '../../../../router/pageRoutes';
import PrimaryLink from '../../../buttons/PrimaryLink';
import { ArrowLeftIcon } from '../../../icons/SvgIcons';

export const LinkToBlog: React.FC = () => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  return (
    <Link
      href={pageRoutes.Blog}
      target="_blank"
      rel="noreferrer"
      className="flex gap-2"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      Blog
      <span className={classnames('transition ease-in-out', { 'rotate-[125deg]': isHovering, 'rotate-0': !isHovering })}>
        <ArrowLeftIcon />
      </span>
    </Link>
  );
};
LinkToBlog.displayName = 'LinkToBlog';

export const LinkToHomePage: React.FC = () => {
  return (
    <Link href={pageRoutes.Index}>
      <Image src="/static/logo.svg" alt="Gaspar Logo" width={59} height={48} />
    </Link>
  );
};

export const LinkToDemoPage: React.FC<{ className?: string }> = (props) => {
  return (
    <PrimaryLink className={classnames('bg-blueElectric text-pureWhite', props.className)} href={pageRoutes.Demo}>
      Request a Demo
    </PrimaryLink>
  );
};
