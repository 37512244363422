"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomSubarray = exports.firstItemFromArray = exports.arraysHaveTheSameValues = exports.extractStatsFromNumberedArray = exports.deduplicateArrayObjects = exports.deduplicateValues = exports.dedudplicateArray = exports.removeItemFromArray = exports.lookupItemsAroundIndex = exports.areEqualArrays = exports.findFirstItemInArrayFromBottom = exports.findFirstItemInArrayFromTop = void 0;
function findFirstItemInArrayFromTop(inputArray, assertionCallback) {
    for (let index = 0; index < inputArray.length; index++) {
        if (assertionCallback(inputArray[index])) {
            return index;
        }
    }
    return 0;
}
exports.findFirstItemInArrayFromTop = findFirstItemInArrayFromTop;
function findFirstItemInArrayFromBottom(inputArray, assertionCallback) {
    for (let index = inputArray.length - 1; index >= 0; index--) {
        if (assertionCallback(inputArray[index])) {
            return index;
        }
    }
    return inputArray.length - 1;
}
exports.findFirstItemInArrayFromBottom = findFirstItemInArrayFromBottom;
function areEqualArrays(baseArray, targetArray, checkerFunction) {
    if (baseArray === targetArray) {
        return true;
    }
    if (baseArray == null || targetArray == null) {
        return false;
    }
    if (baseArray.length !== targetArray.length) {
        return false;
    }
    for (let i = 0; i < baseArray.length; ++i) {
        if (!checkerFunction(baseArray[i], targetArray[i])) {
            return false;
        }
    }
    return true;
}
exports.areEqualArrays = areEqualArrays;
function lookupItemsAroundIndex(array, targetIndex) {
    const currentItem = array[targetIndex];
    const previousItem = targetIndex - 1 >= 0 ? array[targetIndex - 1] : null;
    const nextItem = targetIndex + 1 < array.length ? array[targetIndex + 1] : null;
    return { currentItem, previousItem, nextItem };
}
exports.lookupItemsAroundIndex = lookupItemsAroundIndex;
function removeItemFromArray(array, targetItem) {
    const index = array.indexOf(targetItem);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}
exports.removeItemFromArray = removeItemFromArray;
function dedudplicateArray(arr) {
    return Array.from(new Set(arr));
}
exports.dedudplicateArray = dedudplicateArray;
function deduplicateValues(entries) {
    return entries.filter((tag, index, array) => array.findIndex((entry) => entry == tag) == index).filter(Boolean);
}
exports.deduplicateValues = deduplicateValues;
function deduplicateArrayObjects(entries, key) {
    return entries
        .filter((baseItem, index, array) => array.findIndex((lookupItem) => lookupItem[key] == baseItem[key]) == index)
        .filter(Boolean);
}
exports.deduplicateArrayObjects = deduplicateArrayObjects;
function extractStatsFromNumberedArray(arr) {
    if (!arr.length) {
        return { max: 0, min: 0, average: 0 };
    }
    let total = 0;
    let max = Number.MIN_SAFE_INTEGER;
    let min = Number.MAX_SAFE_INTEGER;
    arr.forEach((value) => {
        if (value <= min) {
            min = value;
        }
        if (value >= max) {
            max = value;
        }
        total += value;
    });
    const average = total / arr.length;
    return { min, max, average };
}
exports.extractStatsFromNumberedArray = extractStatsFromNumberedArray;
function arraysHaveTheSameValues(inputA, inputB) {
    if (inputA.length !== inputB.length) {
        return false;
    }
    inputA = inputA.sort();
    inputB = inputB.sort();
    return inputA.every((val, index) => val === inputB[index]);
}
exports.arraysHaveTheSameValues = arraysHaveTheSameValues;
function firstItemFromArray(values) {
    return values.length > 0 ? values[0] : null;
}
exports.firstItemFromArray = firstItemFromArray;
function getRandomSubarray(arr, size) {
    const shuffled = arr.slice(0); // Clone the array to avoid modifying the original
    let i = arr.length;
    let temp;
    let index;
    // While there remain elements to shuffle…
    while (i--) {
        // Pick a remaining element…
        index = Math.floor(Math.random() * (i + 1));
        // And swap it with the current element.
        temp = shuffled[i];
        shuffled[i] = shuffled[index];
        shuffled[index] = temp;
    }
    // Return the first `size` elements after shuffling
    return shuffled.slice(0, size);
}
exports.getRandomSubarray = getRandomSubarray;
