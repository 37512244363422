import React, { ErrorInfo } from 'react';

import { ClientLogger } from '../lib/loggers/clientLogger';

type ErrorBoundaryProps = { children: React.ReactNode };
type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ClientLogger.error(`API Error: ${JSON.stringify({ error, errorInfo })}`);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oops, there is an error!</h1>
        </div>
      );
    }
    return (<>{this.props.children}</>) as any;
  }
}
export default ErrorBoundary;
