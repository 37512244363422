import classnames from 'classnames';
import React from 'react';

type FormButtonProps = React.InputHTMLAttributes<HTMLButtonElement> & {
  color: 'green' | 'blue';
  children: React.ReactNode;
};
const FormButton: React.FC<FormButtonProps> = React.memo((props) => {
  const { color, children } = props;
  return (
    <button
      aria-label="Submit form"
      type="submit"
      className={classnames('inline-flex items-center font-medium text-center focus:ring-4 focus:outline-none  rounded-full px-5 py-2', {
        'bg-blueElectric text-pureWhite': color === 'blue',
        'bg-brandGreen text-black': color === 'green',
      })}
    >
      {children}
    </button>
  );
});
FormButton.displayName = 'FormButton';
export default FormButton;
