"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatHyphenatedWord = exports.capitalizeFirstLetter = exports.pluralizeWord = exports.convertStringToLowercaseHyphenated = exports.splitTextLinesInWordLimitedBuffers = exports.createWordLimitedStrings = exports.calculateWordCountInString = exports.splitStringInWords = exports.trimWhitespaceAroundString = exports.parseMultiLineList = exports.tranformToTitleCase = void 0;
const tranformToTitleCase = (input) => {
    return input ? input.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';
};
exports.tranformToTitleCase = tranformToTitleCase;
const parseMultiLineList = (textContent) => {
    const lines = textContent.split('\n');
    // Remove bullets, numbers, and list dilimiters
    const sanitizedLines = lines.map((line) => line.replace(/[0-9.-]/g, '').trim()).filter((line) => line.length !== 0);
    return sanitizedLines;
};
exports.parseMultiLineList = parseMultiLineList;
const trimWhitespaceAroundString = (input) => {
    return input.replace(/^\s+|\s+$/gm, '');
};
exports.trimWhitespaceAroundString = trimWhitespaceAroundString;
const splitStringInWords = (input) => {
    return input.split(/[\s\t]/);
};
exports.splitStringInWords = splitStringInWords;
const calculateWordCountInString = (input) => {
    return (0, exports.splitStringInWords)(input).length;
};
exports.calculateWordCountInString = calculateWordCountInString;
const createWordLimitedStrings = (inputs, maxWordsAllowed) => {
    const wordLimitedLines = [];
    let currentLinesBuffer = [];
    let currentAggregatedWordCount = 0;
    for (let index = 0; index < inputs.length; index++) {
        // Force a buffer clarance if the buffer has exceed the max number of words allowed.
        const shouldClearBuffer = currentAggregatedWordCount >= maxWordsAllowed;
        if (shouldClearBuffer) {
            const lastItemInBuffer = currentLinesBuffer.pop();
            wordLimitedLines.push(currentLinesBuffer.join(' '));
            currentLinesBuffer = [lastItemInBuffer];
            currentAggregatedWordCount = (0, exports.calculateWordCountInString)(lastItemInBuffer);
            continue;
        }
        const textContent = inputs[index];
        currentLinesBuffer.push(textContent);
        currentAggregatedWordCount += (0, exports.calculateWordCountInString)(textContent);
    }
    if (currentLinesBuffer.length !== 0) {
        wordLimitedLines.push(currentLinesBuffer.join(' '));
    }
    return wordLimitedLines;
};
exports.createWordLimitedStrings = createWordLimitedStrings;
const splitTextLinesInWordLimitedBuffers = (textLines, maxWordsAllowed) => {
    // First ensure that are lines can actually fit in the buffer
    const bufferFittingLines = textLines.reduce((acccumulator, section) => [...acccumulator, ...(0, exports.createWordLimitedStrings)((0, exports.splitStringInWords)(section), maxWordsAllowed)], []);
    // Then create the actual text buffers.
    const wordLimitedBuffers = (0, exports.createWordLimitedStrings)(bufferFittingLines, maxWordsAllowed);
    return wordLimitedBuffers;
};
exports.splitTextLinesInWordLimitedBuffers = splitTextLinesInWordLimitedBuffers;
const convertStringToLowercaseHyphenated = (input) => {
    return (0, exports.trimWhitespaceAroundString)(input)
        .split(' ')
        .map((term) => term.toLowerCase())
        .join('-');
};
exports.convertStringToLowercaseHyphenated = convertStringToLowercaseHyphenated;
function pluralizeWord(word, count) {
    return `${word}${count > 1 ? 's' : ''}`;
}
exports.pluralizeWord = pluralizeWord;
function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
function formatHyphenatedWord(word) {
    return word.split('-').map(capitalizeFirstLetter).join(' ');
}
exports.formatHyphenatedWord = formatHyphenatedWord;
