import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import pageRoutes from '../../../../router/pageRoutes';
import { LinkToBlog } from './links';

type PrimaryMenuColumnsProps = {
  className?: string;
  style?: React.CSSProperties;
  onToggle: () => void;
};
export const PrimaryMenuColumns: React.FC<PrimaryMenuColumnsProps> = React.memo((props) => {
  const { className, style, onToggle } = props;
  return (
    <ul role="menu" style={style} className={className}>
      <li role="menuitem">
        <button onClick={onToggle}>Why Gaspar AI</button>
      </li>
      <li role="menuitem">
        <button onClick={onToggle}>Solutions</button>
      </li>
      <li role="menuitem">
        <button onClick={onToggle}>Company</button>
      </li>
      <li role="menuitem">
        <LinkToBlog />
      </li>
    </ul>
  );
});
PrimaryMenuColumns.displayName = 'PrimaryMenuColumns';

type SecondaryMenuColumnsProps = {
  className?: string;
  submenusClassName?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onLinkClick: () => void;
};
export const SecondaryMenuColumns: React.FC<SecondaryMenuColumnsProps> = React.memo((props) => {
  const router = useRouter();
  const { className, children, submenusClassName, style, onLinkClick } = props;
  const pathName = router.pathname;

  const attachPathStatus = useCallback(
    (links: Array<{ href: string; label: string }>) => {
      return links.map((link) => {
        return { ...link, active: link.href === pathName };
      });
    },
    [pathName],
  );

  return (
    <div style={style} className={className}>
      <SubmenuColumn
        title="Why Gaspar AI"
        className={submenusClassName}
        onLinkClick={onLinkClick}
        links={attachPathStatus([
          { href: pageRoutes.HowItWorks, label: 'How It Works' },
          { href: pageRoutes.Benefits, label: 'Benefits' },
          { href: pageRoutes.Pricing, label: 'Pricing' },
          { href: pageRoutes.Integrations, label: 'Integrations' },
          { href: pageRoutes.PortalDocumentation, label: 'Documentation' },
        ])}
      />
      <SubmenuColumn
        title="Solutions"
        className={submenusClassName}
        onLinkClick={onLinkClick}
        links={attachPathStatus([
          { href: pageRoutes.GasparForIT, label: 'For IT' },
          { href: pageRoutes.GasparForHR, label: 'For HR' },
          { href: pageRoutes.GasparForSales, label: 'For Sales' },
          { href: pageRoutes.UseCases, label: 'Use Cases' },
          { href: pageRoutes.Platform, label: 'Platform' },
        ])}
      />
      <SubmenuColumn
        title="Company"
        onLinkClick={onLinkClick}
        className={submenusClassName}
        links={attachPathStatus([
          { href: pageRoutes.AboutUs, label: 'About Us' },
          { href: pageRoutes.FreeTrial, label: 'Free Trial' },
          { href: pageRoutes.ContactUs, label: 'Contact Us' },
          { href: pageRoutes.Demo, label: 'Request Demo' },
        ])}
      />
      {children}
    </div>
  );
});
SecondaryMenuColumns.displayName = 'SecondaryMenuColumns';

type SubmenuColumnProps = {
  title: string;
  className?: string;
  links: Array<{ href: string; label: string; active: boolean }>;
  onLinkClick?: () => void;
};
export const SubmenuColumn: React.FC<SubmenuColumnProps> = React.memo((props) => {
  const { className, onLinkClick, title, links } = props;
  return (
    <div className="mb-2 px-4 md:px-0 md:mb-0">
      <p className="text-2xl font-bold mb-2 visible md:hidden">{title}</p>
      <ul className={classnames('flex flex-col gap-2 md:gap-3', className)}>
        {links.map((link, index) => (
          <li key={`link-${index}`} className="text-gray text-xl md:text-lg hover:text-purple font-bold md:font-normal text-left">
            <Link
              href={link.href}
              className={classnames('block w-full', {
                'text-purple': link.active,
              })}
              onClick={onLinkClick}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
});
SubmenuColumn.displayName = 'SubmenuColumn';
