import classnames from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

const PrimaryLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = React.memo((props) => {
  const { children, className, ...restProps } = props;
  return (
    <Link
      className={classnames('select-none inline-flex rounded-full items-center justify-center py-1 px-5', className)}
      {...(restProps as LinkProps)}
    >
      {children}
    </Link>
  );
});
PrimaryLink.displayName = 'PrimaryLink';
export default PrimaryLink;
