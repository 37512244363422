import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import pageRoutes from '../../../router/pageRoutes';
import { LinkedInButton, TwitterButton } from '../../buttons/SocialMediaButtons';
import GetInTouchEmailOnlyForm from '../../forms/GetInTouchEmailOnlyForm';
import FooterNavigation from './FooterNavigation';

type FooterProps = { className?: string };
const Footer: React.FC<FooterProps> = (props) => {
  const { className } = props;
  return (
    <footer aria-label="Site Footer" className="text-pureWhite pb-6 bg-gradient-to-b from-black to-blueElectric/20">
      <div className={classnames(className)}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-center sm:text-left">
          <div>
            <div className="flex">
              <Link href={pageRoutes.Index}>
                <Image src="/static/logo.svg" alt="Gaspar Logo" width={59} height={48} />
              </Link>
            </div>
            <FooterNavigation />
          </div>
          <div className="my-5 md:my-0">
            <p className="text-left text-4xl mb-4 ">Let&#39;s get in touch</p>
            <GetInTouchEmailOnlyForm />
          </div>
          <div className="flex flex-col md:items-end">
            <div>
              <p className="text-left text-4xl mb-4">Find us</p>
              <div className="flex gap-2 align-middle  sm:justify-start">
                <LinkedInButton type="follow" />
                <TwitterButton type="follow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center mt-4 text-pureWhite/70">Copyright @ Gaspar.ai</p>
    </footer>
  );
};
Footer.displayName = 'Footer';
export default Footer;
