import classnames from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

type RoundLinkProps = React.HTMLProps<HTMLAnchorElement> & {
  borderColor?: string;
};
const RoundLink: React.FC<RoundLinkProps> = React.memo((props) => {
  const { children, className, borderColor, ...restProps } = props;
  return (
    <Link
      style={{ borderColor }}
      {...(restProps as LinkProps)}
      className={classnames(
        'select-none inline-flex  border-2 rounded-full items-center justify-center p-2',
        {
          'border-purple': !borderColor,
        },
        className,
      )}
    >
      {children}
    </Link>
  );
});
RoundLink.displayName = 'RoundLink';
export default RoundLink;
