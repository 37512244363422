import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

import { MOBILE_MENU_ACTIVATION_WIDTH } from '../constants';
import Menu from './Menu';
import MobileMenu from './MobileMenu';

enum HeaderMode {
  Default = 'default',
  Mobile = 'mobile',
}

type HeaderProps = { className?: string };
const Header: React.FC<HeaderProps> = (props) => {
  const { className } = props;
  const [containerRef, { width }] = useMeasure();
  const [mode, setMode] = useState<HeaderMode>(HeaderMode.Default);

  useEffect(() => {
    if (mode === HeaderMode.Default && width < MOBILE_MENU_ACTIVATION_WIDTH) {
      setMode(HeaderMode.Mobile);
    }
    if (mode === HeaderMode.Mobile && width >= MOBILE_MENU_ACTIVATION_WIDTH) {
      setMode(HeaderMode.Default);
    }
  }, [mode, width]);

  return (
    <header ref={containerRef} className={classnames(className)} aria-label="Site Header">
      {/* Desktop Menu */}
      {mode === HeaderMode.Default && <Menu />}
      {/* Mobile Menu */}
      {mode === HeaderMode.Mobile && <MobileMenu />}
    </header>
  );
};
Header.displayName = 'Header';
export default Header;
