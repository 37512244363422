import classnames from 'classnames';
import React, { useRef } from 'react';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { HEADER_MENU_WIDTH } from '../constants';
import OverlayMenu from './common/OverlayMenu';
import { PrimaryMenuColumns, SecondaryMenuColumns } from './common/columns';
import { LinkToDemoPage, LinkToHomePage } from './common/links';

const Menu: React.FC = () => {
  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  useClickOutside({
    ref: clickOutsideRef,
    onClickOutside: () => setTimeout(() => setIsExpanded(false), 100),
  });
  return (
    <div className={classnames('flex justify-between items-center')}>
      <LinkToHomePage />
      <div ref={clickOutsideRef}>
        <nav aria-label="Site Menu Navigation">
          <PrimaryMenuColumns
            style={{ width: HEADER_MENU_WIDTH }}
            className={classnames('flex font-semibold flex-row justify-between mx-auto text-pureWhite text-lg')}
            onToggle={() => setIsExpanded(!isExpanded)}
          />
        </nav>
        <OverlayMenu isVisible={isExpanded}>
          <SecondaryMenuColumns
            onLinkClick={() => setIsExpanded(false)}
            style={{ left: -130, width: HEADER_MENU_WIDTH - 155 }}
            className={classnames('relative flex flex-row justify-between gap-4 mx-auto text-pureWhite text-lg')}
          />
        </OverlayMenu>
      </div>
      <LinkToDemoPage />
    </div>
  );
};
Menu.displayName = 'Menu';
export default Menu;
