const pageRoutes = {
  AboutUs: '/our-company',
  Benefits: '/ai-in-the-workplace-benefits',
  Blog: '/blog',
  ContactUs: '/contact-us',
  CookiesPolicy: '/cookies-policy',
  Demo: '/demo-request',
  GasparForHR: '/solutions/hr-helpdesk',
  GasparForIT: '/solutions/ai-it-helpdesk',
  GasparForSales: '/solutions/sales-automation',
  Index: '/',
  FreeTrial: '/free-trial',
  HowItWorks: '/employee-helpdesk-capabilities',
  Integrations: '/integrations',
  Platform: '/solutions/ai-helpdesk-software',
  PortalDocumentation: '/admin-hub',
  Pricing: '/pricing',
  PrivacyPolicy: '/privacy-policy',
  TermsAndConditions: '/terms-conditions',
  UseCases: '/solutions/ai-employee-helpdesk',
  LandingPageAtlassian: '/atlassian-integration',
  LandingPageSlack: '/slack-integration',
  LandingPageTeams: '/microsoft-teams-integration',
  LandingPageFreshservice: '/freshservice-integration',
  LandingPageXenceGuide: '/xence-ai-installation',
  LandingPageXenceAI: '/xence-ai-jira-chatbot',
} as const;

export type PageRoute = (typeof pageRoutes)[keyof typeof pageRoutes];

export default pageRoutes;
