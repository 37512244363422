import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

import { GoogleAnalyticsTrackingId, isProduction } from '../../constants';
import { HEADER_HEIGHT } from './constants';
import Footer from './footer/Footer';
import Header from './header/Header';

// Dynamically import with SSR disabled
const CookiesModalDynamicWrapper = dynamic(() => import('../modals/CookiesModalDynamicWrapper'), { ssr: false });

type AppContainerProps = {
  className?: string;
  children?: React.ReactNode;
};
const AppContainer: React.FC<AppContainerProps> = (props) => {
  const { className, children } = props;
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://gaspardesk.com" />
      </Head>
      <div className={classnames('flex flex-col h-screen justify-between relative', className)}>
        <CookiesModalDynamicWrapper />
        <div style={{ height: HEADER_HEIGHT }} className={`bg-black fixed w-full flex items-center z-20`}>
          <Header className="w-full max-w-screen-2xl px-8 mx-auto" />
        </div>
        <main style={{ marginTop: HEADER_HEIGHT }} className="relative">
          {children}
        </main>
        <Footer className="w-full max-w-screen-2xl py-8 px-8 mx-auto" />
        {isProduction && (
          <>
            {/* Google tag (gtag.js) */}
            <Script
              async
              id="google-analytics-tag"
              src={`https://www.googletagmanager.com/gtag/js?id=${GoogleAnalyticsTrackingId}`}
              strategy="afterInteractive"
            />
            <Script id="google-analytics-init" async strategy="afterInteractive">
              {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GoogleAnalyticsTrackingId}');`}
            </Script>
            {/* Hubspot script loader  */}
            <Script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5552059.js"></Script>
            {/* LinkedIn Insights Tag */}
            <Script id="linkedin-tags-init" type="text/javascript">
              {`_linkedin_partner_id = "5690161";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
            </Script>
            <Script id="linktr-init" type="text/javascript">
              {`(function (l) {if (!l) { window.lintrk = function (a, b) {   window.lintrk.q.push([a, b]); }; window.lintrk.q = []; } var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript"; b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`}
            </Script>
            {/* LinkedIn Campaigns Tag */}
            <Script id="linkedin-campaign-init" type="text/javascript">
              {`(function (w, d, t) { _ml = w._ml || {}; _ml.eid = "81430"; _ml.cid = "300f5d25-35bd-4bc8-a7c4-d15933e738de"; var s, cd, tag; s = d.getElementsByTagName(t)[0]; cd = new Date(); tag = d.createElement(t); tag.async = 1; tag.src = "https://ml314.com/tag.aspx?" + cd.getDate() + cd.getMonth(); s.parentNode.insertBefore(tag, s);})(window, document, "script");`}
            </Script>
          </>
        )}
      </div>
    </>
  );
};
AppContainer.displayName = 'AppContainer';
export default AppContainer;
