import type { DefaultSeoProps } from 'next-seo';

const defaultSeoConfig: DefaultSeoProps = {
  canonical: 'https://www.gaspar.ai/',
  title: 'Gaspar AI',
  themeColor: '#ffffff',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.gaspar.ai/',
    title: 'Gaspar AI',
    siteName: 'Gaspar AI',
  },
};
export default defaultSeoConfig;
