import React, { useEffect, useRef, useState } from 'react';

import { QuestionMarkIcon } from '../icons/SvgIcons';

type TooltipProps = {
  content: string;
  width?: number;
};

export const Tooltip: React.FunctionComponent<TooltipProps> = (props): JSX.Element => {
  const { content, width } = props;
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left } = container.current.getBoundingClientRect();

        const tooltipHeight = tooltipRef.current.clientHeight;
        const tooltipWidth = tooltipRef.current.clientWidth;

        tooltipRef.current.style.left = clientX - left - tooltipWidth / 2 + 'px';
        tooltipRef.current.style.top = -20 - tooltipHeight + 'px';
      }}
      className="group relative inline-block cursor-pointer"
    >
      <QuestionMarkIcon />

      <span
        ref={tooltipRef}
        style={{ width: `${width | 300}px` }}
        className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-center text-white text-sm p-2 rounded absolute top-full mt-2 break-words"
      >
        {content}
      </span>
    </div>
  );
};
Tooltip.displayName = 'Tooltip';

export const DisappearingTooltip: React.FunctionComponent<{ message?: string; width?: number; children: React.ReactNode }> = (
  props,
): JSX.Element => {
  const { message, width, children } = props;

  const [showTooltip, setShowTooltip] = useState(true);
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  // Upon initial mount, add a timeout of 5 seconds to stop showing
  useEffect(() => {
    if (tooltipRef.current && container.current) {
      tooltipRef.current.style.top = -10 - tooltipRef.current.clientHeight + 'px';
    }
    const timeout = setTimeout(() => setShowTooltip(false), 5000);
    return () => clearTimeout(timeout);
  }, []);

  if (!showTooltip) {
    return <>{children}</>;
  }
  return (
    <div ref={container} className="relative ">
      <span
        ref={tooltipRef}
        className="bg-black text-center text-white text-sm rounded absolute break-words px-1 py-2"
        // Positioning the tooltip above the children
        // Center it horizontally and move it upwards by 100% of its height
        style={{
          width: `${width || 250}px`,
          left: '50%', // Center horizontally
          transform: 'translateX(-50%)', // Correct the centering offset
          zIndex: 10, // Make sure the tooltip is on top
        }}
      >
        {message}
      </span>
      {children}
    </div>
  );
};
DisappearingTooltip.displayName = 'DisappearingTooltip';
