import classnames from 'classnames';
import React from 'react';

import { HEADER_HEIGHT } from '../../constants';

type OverlayMenuProps = {
  isVisible: boolean;
  children: React.ReactNode;
};
const OverlayMenu: React.FC<OverlayMenuProps> = React.memo((props) => {
  const { isVisible, children } = props;
  return (
    <div
      aria-label="Site OverlaySubMenu Navigation"
      style={{ top: HEADER_HEIGHT }}
      className={classnames(
        'absolute left-0 right-0 z-40 bg-black text-pureWhite py-4 border-t-1 border-purple/60',
        'animate-submenuFadeIn',
        'h-[100vh] md:h-auto overflow-scroll',
        {
          visible: isVisible,
          hidden: !isVisible,
        },
      )}
    >
      {children}
    </div>
  );
});
OverlayMenu.displayName = 'OverlayMenu';
export default OverlayMenu;
