"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anyOf = exports.EmptyPromiseFn = exports.EmptyFn = exports.isNonEmptyArray = exports.isNonEmptyString = exports.def = void 0;
function def(value) {
    return value !== null && value !== undefined;
}
exports.def = def;
function isNonEmptyString(value) {
    return value !== null && value !== undefined && value !== '';
}
exports.isNonEmptyString = isNonEmptyString;
function isNonEmptyArray(value) {
    return value !== null && value !== undefined && value.length > 0;
}
exports.isNonEmptyArray = isNonEmptyArray;
function EmptyFn() { }
exports.EmptyFn = EmptyFn;
function EmptyPromiseFn(input) {
    return () => Promise.resolve(input);
}
exports.EmptyPromiseFn = EmptyPromiseFn;
function anyOf(value, ...allowedValues) {
    return allowedValues.includes(value);
}
exports.anyOf = anyOf;
