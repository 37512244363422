"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNumber = exports.isDefinedNumber = exports.isNonZeroNumber = exports.roundNumber = exports.mapValueToPercentage = exports.convertFractionToDecimalNumber = exports.castToNumber = exports.generateRandomInt = exports.numberInRange = void 0;
const numberInRange = (value, minValue, maxValue) => {
    return value >= minValue && value <= maxValue;
};
exports.numberInRange = numberInRange;
const generateRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
exports.generateRandomInt = generateRandomInt;
const castToNumber = (value) => {
    if (typeof value === 'string') {
        return Number(value);
    }
    return value;
};
exports.castToNumber = castToNumber;
const convertFractionToDecimalNumber = (ratio, decimalPlaces = 2) => {
    if (!ratio) {
        return 0;
    }
    return (0, exports.roundNumber)(100 * ratio, decimalPlaces);
};
exports.convertFractionToDecimalNumber = convertFractionToDecimalNumber;
const mapValueToPercentage = (params) => {
    const { value, min, max } = params;
    return parseFloat(((value - min) / max).toFixed(2)) * 100;
};
exports.mapValueToPercentage = mapValueToPercentage;
const roundNumber = (value, decimalPlaces = 2) => Number(value.toFixed(decimalPlaces));
exports.roundNumber = roundNumber;
const isNonZeroNumber = (value) => !!value && value !== Infinity && value !== 0;
exports.isNonZeroNumber = isNonZeroNumber;
const isDefinedNumber = (value) => !!value && !isNaN(value);
exports.isDefinedNumber = isDefinedNumber;
const isNumber = (value) => {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
};
exports.isNumber = isNumber;
