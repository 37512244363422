export class ClientLogger {
  public static error(body: string) {
    const options = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body };
    const logEndpoint = '/api/log-error';
    if (!!navigator.sendBeacon) {
      navigator.sendBeacon(logEndpoint, body);
      return;
    }
    fetch(logEndpoint, options);
  }
}
